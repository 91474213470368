import request from '@/utils/request'

// 获取店铺列表
export function getShopList(params) {
    return request({
        url: '/store/lst',
        method: 'get',
		params:params,
        isLogin: false,
    })
}
//获取店铺详情
export function getShopDetail(params) {
    return request({
        url: '/store/info',
        method: 'get',
		params:params,
        isLogin: true,
    })
}
//添加购物车
export function addShoppingCart(params) {
    return request({
        url: '/cart/add',
        method: 'post',
        params,
		isLogin: true,
    })
}
//获取购物车列表
export function getCartList(data) {
    return request({
        url: '/cart/lst',
        method: 'get',
        params:data,
		isLogin: true,
    })
}
//删除购物车
export function deleteCart(data) {
    return request({
        url: '/cart/del',
        method: 'DELETE',
        params:data,
		isLogin: true,
    })
}
//修改商品数量
export function changeGoodsNum(data) {
    return request({
        url: '/cart/count',
        method: 'post',
        params:data,
		isLogin: true,
    })
}

//关注店铺
export function followShop(data) {
    return request({
        url: '/follow/add',
        method: 'post',
        params:data,
		isLogin: true,
    })
}
//获取店铺预约时间
export function getShopDate(data) {
    return request({
        url: '/store/appointList',
        method: 'get',
        params:data,
		isLogin: true,
    })
}

