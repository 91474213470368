<template>
	<div class="shop-detail">

		<van-swipe :autoplay="3000">
			<van-swipe-item v-for="(image, index) in goodsDetail.images" :key="index">

				<img class="shop-image" v-lazy="imageUrl+image" />
			</van-swipe-item>
		</van-swipe>

		<div class="shop-information">
			<div class="shop-title">
				<div style="padding-right: 10px;">
					<span class="shop-name">{{goodsDetail.name}}</span>
					<div class="good-price">
						<span>￥</span><span style="font-size: 26px;">{{goodsDetail.price}}</span>
					</div>

				</div>

			</div>
			<div class="goods-appointment" v-if="type==3">

				<van-tabs @click="seleteDate">
					<van-tab v-for="item in dateList" :name="item.dateAll">
						<template #title>
							<div>{{item.date}} </div>
							<div>{{item.week}}</div>
						</template>
					</van-tab>
				</van-tabs>
				<van-radio-group v-model="checked">
					<van-cell-group>
						<van-cell :title="item.time_quantum" clickable @click="checked = item.id"
							v-for="(item,index) in appointment">
							<template #default>
								<span class="date-text">预约总数:{{item.amount}}&nbsp;&nbsp;&nbsp;</span>
								<span class="date-text">可预约数:{{item.appoint_count}}</span>
							</template>
							<template #right-icon>
								<van-radio :name="item.id" />
							</template>

						</van-cell>

					</van-cell-group>
				</van-radio-group>
			</div>
			<div class="shop-rule">
				<p class="shop-rule-title">商品详情</p>
				<p class="shop-rule-content" v-html="goodsDetail.detail">

				</p>
			</div>
			<div class="shop-rules">
				<p class="shop-rule-titles">购买须知</p>
				<p class="shop-rule-contents" v-html="goodsDetail.buy_detail">
				</p>
			</div>
		</div>

		<van-action-sheet v-model:show="show">
			<div class="goods-sku-content">
				<div class="goods-sku-top">
					<van-image :src="imageUrl+goodsDetail.image"></van-image>
					<div class="goods-sku-information">
						<!-- <p class="goods-sku-name">{{goodsDetail.name}}</p> -->
						<p class="goods-sku-price"><span style="font-size: 14px;">￥</span>{{price}}</p>
						<p class="goods-sku-stock">库存:{{stock}}</p>
					</div>
				</div>
				<div class="goods-sku-attrs">
					<div class="goods-sku-attr">
						<p class="goods-sku-title">{{goodsDetail.attrs.attr[0].attr_name}}</p>

						<div v-for="(item,i) in goodsDetail.attrs.value" @click="seleteSku(item,i)" ref="sku"
							:class="seleteSkuStatus==i?'checkedSku':'sku-attr-item'">
							<div class="goods-sku-value"><span>{{item.sku}}</span></div>
						</div>

					</div>
					<div class="goods-sku-attr">
						<p class="goods-sku-title">数量</p>
						<van-stepper v-model="goodsNum" :max="stock" />
					</div>
				</div>
				<van-button type="primary" block color="#ffd100" @click="skuComfirm()">确定</van-button>
			</div>

		</van-action-sheet>

		<van-action-bar>
			<!-- <van-action-bar-icon icon="chat-o" text="客服" dot /> -->
			<!-- <van-action-bar-icon icon="cart-o" text="购物车" :badge="cartNum" @click="goShopingCart" /> -->
			<van-action-bar-icon icon="cart-o" text="购物车" @click="goShopingCart" />
			<van-action-bar-icon icon="shop-o" text="店铺" @click="goShop" />
			<van-action-bar-icon icon="star" text="收藏" @click="addCollect(goodsDetail.id)" v-if="is_collect"
				color="#ffd100" />
			<van-action-bar-icon icon="star-o" text="收藏" @click="addCollect(goodsDetail.id)" v-else />
			<!-- <div style="display: flex;width: 100%;" v-if="isShow!=3">
				<van-action-bar-button type="warning" text="加入购物车" @click="addCart" />
				<van-action-bar-button type="danger" text="立即购买" @click="goOrderConfirm" />
			</div>
			<div style="display: flex;width: 100%;" v-else>

				<van-action-bar-button type="danger" text="立即购买" @click="goOrderConfirm" />
			</div> -->
<div style="display: flex;width: 100%;" >
				<van-action-bar-button type="warning" text="加入购物车" @click="addCart" />
				<van-action-bar-button type="danger" text="立即购买" @click="goOrderConfirm" />
			</div>
		</van-action-bar>

	</div>
</template>

<script>
	import {
		ref
	} from 'vue';
	import {
		Toast
	} from 'vant';
	import {
		Lazyload
	} from 'vant';
	import {
		getIconList,
		getGoodsList
	} from '@/api/index'
	import {
		getGoodsDetail,
		addCollect
	} from '@/api/goods'
	import {
		addShoppingCart,
		getCartList,
		getShopDetail,
		getShopDate
	} from '@/api/shop'

	import {
		delList
	} from '@/api/user'

	import dateFormat from '@/utils/date'
	export default {
		name: 'goodsDetail',
		setup() {
			const active = ref(0);
			const show = ref(false);
			const starDate = dateFormat.getLastOrNextFewDateBy(1)
			const endDate = dateFormat.getLastOrNextFewDateBy(7)
			const dateList = dateFormat.getAllDates(starDate, endDate)
			return {
				active,
				show,
				dateList
			};
		},
		data() {
			return {

				imageUrl: this.url.imageUrl,
				goodsDetail: '',
				isShow: '',
				cartNum: 0,
				is_collect: false,
				showDate: false,
				checked: '',
				appointment: '',
				initialDate: this.dateList[0].dateAll,
				type: this.$route.query.type,

				seleteSkuStatus: 0,
				stock: 0,
				price: 0,
				goodsNum: 1,
				skuUnion: '',
				buyType: '',
				totalNum:[],
			};
		},
		mounted() {
			// console.log(this.$route.query.type)
			let paramas = {}
			paramas.id = this.$route.query.id
			this.getGoodsDetail(paramas).then((res) => {
				console.log(res)
				this.goodsDetail = res.data
				// console.log(this.goodsDetail.buy_detail)
				// console.log(this.goodsDetail.detail)
				this.is_collect = res.data.is_collect
				this.isShow = res.data.type
				this.shopId = res.data.sid
				if (res.data.attrs) {
					this.price = res.data.attrs.value[0].price
					this.stock = res.data.attrs.value[0].stock
					this.skuUnion = res.data.attrs.value[0].union
				}
				let paramas = {}
				paramas.id = res.data.sid
				this.getShopDetail(paramas)
				this.seleteDate(this.dateList[0].dateAll)
			})

		},
		methods: {
			//选择商品规格
			seleteSku(item, i) {
				console.log(item)
				this.seleteSkuStatus = i
				// this.$refs.sku.addclass
				this.price = item.price
				this.stock = item.stock
				this.skuUnion = item.union
			},
			//跳转确认订单
			goOrderConfirm() {

				this.buyType = 1
				if (this.goodsDetail.attrs) {
					this.show = true
					return false
				} else {
					this.skuComfirm()
				}

			},
			//选择时间
			seleteDate(name) {
				this.initialDate = name
				var params = {}
				params.date = this.initialDate
				params.sid = this.shopId
				getShopDate(params).then((res) => {
					console.log(res)
					this.appointment = res.data
				})
			},
			//添加收藏
			addCollect(id) {

				if (this.is_collect) {
					delList({
						pid: id
					}).then((res) => {
						if (res.code == 200) {
							this.is_collect = !this.is_collect
							this.$toast('取消成功')
						}
					})
				} else {
					addCollect({
						pid: id
					}).then((res) => {

						if (res.code == 200) {
							this.is_collect = !this.is_collect
							this.$toast('收藏成功')
						}
					})
				}



			},
			//购物车
			goShopingCart() {

				this.$router.push({
					path: '/shopCart',
					query: {
						shopId: this.shopId
					}
				})
			},
			//店铺详情
			goShop() {

				this.$router.push({
					path: '/shopDetail',
					query: {
						id: this.shopId
					}
				})
			},
			onClickIcon() {
				Toast('点击图标');
			},
			onClickButton() {
				Toast('点击按钮');
			},
			//店铺详情
			async getShopDetail(paramas) {
				await getShopDetail(paramas).then((res) => {
					console.log(res)
					this.cartNum = res.data.cart_count
				})
			},

			//选择规格确认
			skuComfirm() {
				// console.log(this.stock)
				// this.totalNum.push(this.goodsNum)
				// let sum = 0;
				// this.totalNum.forEach(item =>{
				//     sum = sum + item
				// })
				// console.log(sum)
				// if(this.stock < sum){
				// 	Toast('库存不足');
				// 	return
				// }else{
				// 	console.log('过')
				// }
				var paramas = {}
				paramas.count = this.goodsNum
				paramas.id = this.goodsDetail.id
				paramas.union = this.skuUnion
				// console.log(paramas)
				if (this.buyType == 0) {
					addShoppingCart(paramas).then((res) => {
						console.log(res)
						if (res.code == 200) {
							this.cartNum = res.data.count
							this.$toast('成功加入购物车,请点击查看')
							this.show = false
						}
					})
				} else if (this.buyType == 1) {
					if (this.type == 3) {
						if (this.checked) {
							this.$router.push({
								path: '/orderConfirm',
								query: {
									pid: this.$route.query.id,
									appoint_id: this.checked,
									appoint_date: this.initialDate
								}
							})
						} else {
							this.$toast('请选择预约时间')
						}
					} else {
						this.$router.push({
							path: '/orderConfirm',
							query: {
								pid: this.$route.query.id,
								union: this.skuUnion,
								count: this.goodsNum

							}
						})
					}

				}

			},
			//添加购物车
			addCart() {
				this.buyType = 0
				if (this.goodsDetail.attrs) {
					this.show = true
					return false
				} else {
					this.skuComfirm()
				}


				// addShoppingCart({
				// 	id: this.goodsDetail.id
				// }).then((res) => {
				// 	this.cartNum = res.data.count
				// })
			},
			//商品详情
			async getGoodsDetail(paramas) {
				return await getGoodsDetail(paramas)

			},


		}
	};
</script>

<style lang="less" scoped>
	.shop-detail {
		.van-swipe-item{
			// text-align: center;
			// background-color: #FFFFFF;
			.shop-image {
				// width: auto;
				width: 100%;
				height: 260px;
				// object-fit: cover; 
			}
			
		}
		
		.checkedSku {
			background-color: #F2F2F2;
			color: red;
			display: flex;
			align-items: center;
			justify-content: center;

			padding: 3px 15px;
			margin-right: 10px;
		}

		.goods-sku-content {
			padding: 10px 15px;

			.goods-sku-attrs {
				margin-bottom: 20px;

				p {
					margin: 0;
				}

				.goods-sku-attr {
					display: flex;
					align-items: center;
					margin-top: 20px;

					.goods-sku-title {
						margin-right: 20px;
					}

					.sku-attr-item {
						display: flex;
						align-items: center;
						justify-content: center;
						background-color: #F6F6F6;
						padding: 3px 15px;
						margin-right: 10px;
					}
				}
			}

			.goods-sku-top {
				display: flex;
				align-items: center;
				justify-content: flex-start;

				.van-image {
					width: 88px;
					height: 88px;
				}

				.goods-sku-information {
					display: flex;
					margin-left: 30px;
					flex-direction: column;

					.goods-sku-name {
						font-size: 18px;
					}

					.goods-sku-stock {
						color: #666666;
						font-size: 14px;
						margin-top: 10px;
					}

					.goods-sku-price {
						color: red;
						font-size: 24px;
					}

					p {
						margin: 0;
					}
				}
			}
		}

		.goods-appointment {
			margin-top: 15px;

			.date-text {
				font-size: 12px;
			}
		}

		::v-deep(.van-radio) {
			margin-left: 20px;
		}

		::v-deep(.van-cell) {
			justify-content: space-between;
			align-items: center;
		}

		::v-deep(.van-cell__title, .van-cell__value) {
			flex: none;
		}

		::v-deep(.van-tab__text) {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-bottom: 8px;
		}

		.options-area {
			display: flex;
			flex-wrap: wrap;
			background-color: #FFFFFF;

			.options {
				display: flex;
				align-items: center;
				flex-direction: column;
				width: 20%;
				margin-bottom: 10px;

				.options-icon {
					width: 55px;
					height: 55px;

				}

				.options-name {
					margin-top: 5px;
					font-size: 12px;
					color: #666666;
				}
			}
		}

		.goods-item {
			display: flex;
			align-items: flex-start;
			background-color: #FFFFFF;
			border-radius: 5px;
			margin-bottom: 10px;
			padding: 5px 15px;
			justify-content: flex-start;

			.goods-image {
				width: 88px;
				height: 88px;
				border-radius: 8px;
			}

			.goods-name {
				font-size: 14px;
				margin-left: 10px;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}
		}

		
		.shop-information {
			padding: 10px 15px;
			background-color: #FFFFFF;

			.shop-title {
				display: flex;
				align-items: center;
				justify-content: space-between;

				.good-price {
					display: flex;
					align-items: flex-start;
					color: red;
					font-weight: bold;
				}

				.shop-time {
					color: #666666;
					font-size: 14px;
				}

				.shop-name {
					margin-bottom: 15px;
					font-size: 18px;
					font-weight: bold;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
				}
			}

			.shop-address-phone {
				margin-top: 5px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.shop-address {
					font-size: 12px;
				}
			}

			.shop-rule {
				margin-top: 20px;
				padding-bottom: 40px;

				.shop-rule-title {
					font-size: 16px;
					font-weight: bold;
				}

				.shop-rule-content {
					font-size: 15px;
					color: #777777;
					line-height: 20px;
					letter-spacing: 0.5px;
					
				}
			}

			.shop-rules {
				margin-top: 20px;
				padding-bottom: 40px;

				.shop-rule-titles {
					font-size: 16px;
					font-weight: bold;
				}

				.shop-rule-contents {
					font-size: 14px;
					color: #777777;
					line-height: 20px;
					letter-spacing: 0.5px;

					p {
						img {
							width: 100%;
							height: 150px !important;
						}
					}
				}
			}
		}

		.buy-btn {
			width: 96%;
			height: 44px;
			margin: 30px auto;

			border-radius: 20px;
			background-color: rgb(255, 209, 0);
			display: flex;
			align-items: center;
			justify-content: center;

			span {
				color: #FFFFFF;
				font-size: 16px;
			}
		}

		.shop-cart {
			position: fixed;
			bottom: 50px;
			right: 15px;
		}

		.goods-bottom {
			height: 60px;
			position: fixed;
			bottom: 0;
			width: 100%;
			background-color: #FFFFFF;
			display: flex;
			align-items: center;
			padding: 10px 15px;
			justify-content: flex-end;
			box-sizing: border-box;
			border-top: solid 1px #F6F6F6;

			.goods-btns {
				display: flex;
				align-items: center;

				.add-shoppingcart {
					display: flex;
					justify-content: center;
					align-items: center;
					color: #FFFFFF;
					height: 40px;
					width: 120px;
					border-top-left-radius: 20px;
					border-bottom-left-radius: 20px;
					background-color: rgb(255, 209, 0);
					font-size: 16px;
				}

				.payment-btn {
					display: flex;
					justify-content: center;
					align-items: center;
					color: #FFFFFF;
					height: 40px;
					width: 120px;
					border-top-right-radius: 20px;
					border-bottom-right-radius: 20px;
					background-color: #FF8C00;
					font-size: 16px;
				}
			}
		}
	}
	.w-e-text-container{
		height: auto!important;
	}
</style>
