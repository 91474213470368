Date.prototype.Format = function(fmt) { //author: meizz
	var o = {
		"M+": this.getMonth() + 1, //月份
		"d+": this.getDate(), //日
		"h+": this.getHours(), //小时
		"m+": this.getMinutes(), //分
		"s+": this.getSeconds(), //秒
		"q+": Math.floor((this.getMonth() + 3) / 3), //季度
		"S": this.getMilliseconds() //毫秒
	};
	if (/(y+)/.test(fmt))
		fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
	for (var k in o)
		if (new RegExp("(" + k + ")").test(fmt))
			fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k])
				.length)));
	return fmt;
}

function getLastOrNextFewDateBy(day) {
	var today = new Date();
	var lastOrNextDate = today.getTime() + 1000 * 60 * 60 * 24 * day;
	today.setTime(lastOrNextDate);
	var year = today.getFullYear();
	var month = today.getMonth() + 1;
	var date = today.getDate();
	month = month < 10 ? '0' + month : month;
	date = date < 10 ? '0' + date : date;
	return year + "-" + month + "-" + date;
}

function week(val) {

	function isNull(val) {
		if (val == null || typeof val == "undefined") {
			return true;
		}
		return false;
	};
	var date;
	if (isNull(val)) {
		date = new Date();
	} else {
		var dateArray = val.split("-");
		date = new Date(dateArray[0], parseInt(dateArray[1] - 1), dateArray[2]);
	}
	return val = "周" + "日一二三四五六".charAt(date.getDay());


}

function getAllDates(begin, end) { //返回 日期的数组 如 ['2020-07-10','2020-07-11']

	var arr = [];
	var ab = begin.split("-");
	var ae = end.split("-");
	var db = new Date();
	db.setUTCFullYear(ab[0], ab[1] - 1, ab[2]);
	var de = new Date();
	de.setUTCFullYear(ae[0], ae[1] - 1, ae[2]);
	var unixDb = db.getTime() - 24 * 60 * 60 * 1000;
	var unixDe = de.getTime() - 24 * 60 * 60 * 1000;

	for (var k = unixDb; k <= unixDe;) {

		k = k + 24 * 60 * 60 * 1000;
		arr.push({
			date: new Date(parseInt(k)).Format('MM-dd'),
			dateAll: new Date(parseInt(k)).Format('yyyy-MM-dd'),
			week:week(new Date(parseInt(k)).Format('yyyy-MM-dd')),
			
		})
		// arr.push(dateFormat('MM-dd',new Date(parseInt(k))))
		// arr.push((new Date(parseInt(k))).Format('MM-dd'));
	}
	
	console.log(arr)
	return arr;
}

export default {

	getAllDates,
	getLastOrNextFewDateBy
}
