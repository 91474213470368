import request from '@/utils/request'

// 获取商品详情
export function getGoodsDetail(data) {
    return request({
        url: '/product/info',
        method: 'get',
        params:data
    })
}
//获取店铺商品列表
export function getShopGoodsList(params) {
    return request({
        url: '/product/lst',
        method: 'get',
		params:params,
        isLogin: true,
    })
}

//添加商品收藏
export function addCollect(params) {
    return request({
        url: '/collect/add',
        method: 'POST',
		params:params,
        isLogin: true,
    })
}